<template>
  <dash-page-new
      :title="$t('WestudyPartner')"
      :root="$t('Dashboard')"
      background-color="var(--wsLIGHTCARD)"
      no-body-padding
  >

    <template #header_action>
      <ft-select
          :items="bufferCopyItems"
      >
        <v-sheet
            v-ripple
            style="border: 1px solid var(--wsBACKGROUND)"
            class="wsRoundedLight pointer d-flex align-center px-3 py-2"
        >
          <v-icon :color="wsACCENT" class="mr-2">mdi-link</v-icon>
          <h5 class=" wsACCENT">
            {{ entity.code }}
          </h5>
          <v-icon :color="wsACCENT" class="mr-2">mdi-menu-down</v-icon>

        </v-sheet>
      </ft-select>

    </template>
    <template #default>

      <!-- Header-->
      <div class="mt-5"  >
        <ws-stat-row
            :items="statisticsSelect"
            :class="[{ 'px-10' : !SM } , {'px-4'  : SM }]"
        />

        <ws-navigation-header
            :items="navigationSelect"
            v-model="navigation"
            class="mt-4"
            padding="2px"
        />
      </div>

      <!-- Content -->
      <v-sheet
          :class="[{ 'px-10' : !SM },{ 'px-4'  : SM }]"
          class="flex-grow-1 fill-height"
      >

        <ws-navigation
            :items="navigationSelect"
            v-model="navigation"
            hide-header
        >
          <template #item.businesses>
            <ws-data-table
                :items="entity.businesses"
                :headers="businessHeaders"
            >
              <template #item.date="{item}">
                <h5 class="text-no-wrap" >{{ MONTH_DAY_TIME(item.date , false) }}</h5>
              </template>

              <template #item.name="{item}">
                <h5 class="text-no-wrap wsACCENT" >{{ item.name }}</h5>
              </template>

              <template #item.payment_plan_price="{item}">
                <h5 class="text-no-wrap" :style="`color : ${wsACCENT}`">
                 {{ item.payment_plan_price }} $
                </h5>
              </template>

              <template #item.months_payed="{item}">
                <h5 class="text-center pr-5">{{ item.months_payed }}</h5>
              </template>

              <template #item.ready_payment="{item}">
                <h5 class="text-no-wrap" :style="`color : ${wsACCENT}`">
                  {{ item.ready_payment }}
                  <span v-if="item.ready_payment !== item.expected_payment">
                     / {{ item.expected_payment }}
                  </span>
                  ($)
                </h5>
              </template>

              <template #item.status="{item}">
                <ws-chip
                    class="text-no-wrap"
                    :color="getStatusColor(item.status)"
                    :text="$t(`partner.business.status.${item.status}`)" outlined
                />

              </template>


            </ws-data-table>
          </template>

          <template #item.stats>
            <h1>Payments statistics will be here</h1>
          </template>

        </ws-navigation>



      </v-sheet>

    </template>



  </dash-page-new>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DashWestudyPartner",
  data() {
    return {
      navigation : 'businesses',
      entity : {
        businesses : []
      },
      businessesItems : []
    }
  },
  computed : {
    bufferCopyItems() {
      return [
        { text : 'WeStudy Homepage' ,
          icon : 'mdi-school-outline',
          action :() => this.CLIPBOARD(`https://westudy.ua/?pref=${this.entity.code}`)
        },
        { text : 'WeStudy Register Page' ,
          icon : 'mdi-school-outline',
          action :() => this.CLIPBOARD(`https://westudy.ua/register?pref=${this.entity.code}`)
        },
        { text : 'WeStudy Pricing Page' ,
          icon : 'mdi-school-outline',
          action :() => this.CLIPBOARD(`https://westudy.ua/pricing?pref=${this.entity.code}`)
        },
        { text : 'Copy Referral Code' ,
          icon : 'mdi-content-copy',
          action :() => this.CLIPBOARD(this.entity.code)
        }
      ]
    },
    businessHeaders() {
      return [
        { text : this.$t('Date') , value : 'date' , width : 50 },
        { text : this.$t('Name') , value : 'name'  },
        { text : this.$t('PaymentPlan') , value : 'payment_plan_price' , width : 50  },
        { text : this.$t('partner.business.months_payed') , value : 'months_payed'  , width : 50 },
        { text : this.$t('partner.stats.ready_to_pay') , value : 'ready_payment'  , width : 50 },
        { text : this.$t('Status') , value : 'status'  , width : 50 },
      ]
    },
    statisticsSelect() {
      return [
        { icon : 'mdi-credit-card',
          title : this.$t('partner.stats.total'),
          value : 'total_earn',
          text : `${this.entity.total_earn || 0} $`,
        },
        { icon : 'mdi-cash-multiple',
          title : this.$t('partner.stats.ready_to_pay'),
          value : 'ready_to_pay',
          text : `${this.entity.ready_to_pay || 0} $`,
          text_regular : `${this.entity.expected_earn || 0}`,
        },
        // check-circle-outline
        { icon : 'mdi-open-in-new',
          title : this.$t('partner.stats.clicks'),
          value : 'clicks',
          text : this.entity.clicks || 0,
        },
        { icon : 'mdi-account-plus-outline',
          title : this.$t('partner.stats.business.registered'),
          value : 'completed_businesses_count',
          text : `${this.entity.businesses_count || 0}`,
        },
        { icon : 'mdi-school-outline',
          title : this.$t('partner.stats.business.payed'),
          value : 'completed_businesses_count',
          text : `${this.entity.completed_businesses_count || 0}`,
          text_regular : `${this.entity.payed_businesses_count || 0}`,
        },
        { icon : 'mdi-percent-box-outline',
          title : this.$t('partner.stats.business.percent'),
          value : 'completed_businesses_count',
          text : `${this.successPercent}%`,
        },

      ]
    },
    successPercent() {

      // completed_businesses_count
      let completed =  this.entity.payed_businesses_count || 0
      let total = this.entity.businesses_count || 0
      if ( completed === 0) {
        return 0
      }

      return parseInt(Math.round((completed/total)*100))
    },
    navigationSelect() {
      return [
        { text : this.$t('partner.stats.business.registrations') ,
          value : 'businesses',
        },
        // {
        //   text : this.$t('Payments') ,
        //   value : 'payments',
        // },
      ]
    },
  },
  methods : {
    ...mapActions('adminPartners', [
      'GET_PARTNER_DATA_SELF',
    ]),



    //technical
    getStatusColor(status) {
      switch (status) {
        case 'trial' : return this.wsDARKLIGHT
        case 'in_process' : return this.wsACCENT
        case 'ready' : return this.wsSUCCESS
        default : return this.wsDARKER
      }
    },

    async initPage() {
      let result = await this.GET_PARTNER_DATA_SELF()

      if ( !result ) {
        return this.ERROR()
      }

      this.entity = result

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>