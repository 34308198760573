var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{attrs:{"title":_vm.$t('WestudyPartner'),"root":_vm.$t('Dashboard'),"background-color":"var(--wsLIGHTCARD)","no-body-padding":""},scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ft-select',{attrs:{"items":_vm.bufferCopyItems}},[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"wsRoundedLight pointer d-flex align-center px-3 py-2",staticStyle:{"border":"1px solid var(--wsBACKGROUND)"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-link")]),_c('h5',{staticClass:" wsACCENT"},[_vm._v(" "+_vm._s(_vm.entity.code)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-menu-down")])],1)],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"mt-5"},[_c('ws-stat-row',{class:[{ 'px-10' : !_vm.SM } , {'px-4'  : _vm.SM }],attrs:{"items":_vm.statisticsSelect}}),_c('ws-navigation-header',{staticClass:"mt-4",attrs:{"items":_vm.navigationSelect,"padding":"2px"},model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}})],1),_c('v-sheet',{staticClass:"flex-grow-1 fill-height",class:[{ 'px-10' : !_vm.SM },{ 'px-4'  : _vm.SM }]},[_c('ws-navigation',{attrs:{"items":_vm.navigationSelect,"hide-header":""},scopedSlots:_vm._u([{key:"item.businesses",fn:function(){return [_c('ws-data-table',{attrs:{"items":_vm.entity.businesses,"headers":_vm.businessHeaders},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , false)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap wsACCENT"},[_vm._v(_vm._s(item.name))])]}},{key:"item.payment_plan_price",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(item.payment_plan_price)+" $ ")])]}},{key:"item.months_payed",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-center pr-5"},[_vm._v(_vm._s(item.months_payed))])]}},{key:"item.ready_payment",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(item.ready_payment)+" "),(item.ready_payment !== item.expected_payment)?_c('span',[_vm._v(" / "+_vm._s(item.expected_payment)+" ")]):_vm._e(),_vm._v(" ($) ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ws-chip',{staticClass:"text-no-wrap",attrs:{"color":_vm.getStatusColor(item.status),"text":_vm.$t(("partner.business.status." + (item.status))),"outlined":""}})]}}])})]},proxy:true},{key:"item.stats",fn:function(){return [_c('h1',[_vm._v("Payments statistics will be here")])]},proxy:true}]),model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }